@use 'sass:math';
@import '../../node_modules/modern-css-reset';
@import './scrollAnimate';

$topbar-height: 70px;
$container-width: 1160px;
$container-indent: 16px;

$font-family: 'Montserrat', sans-serif;

$ose-fill: #ff444a;
$black-50: rgba(0, 0, 0, 0.5);
$white: white;
$white-60: rgba(255, 255, 255, 0.6);
$green: #50db94;
$black-100: #000000;
$dark: #1d232a;
$black-20: rgba(0, 0, 0, 0.2);
$dark-rose: #e03c41;
$violet: #c863d0;
$purple: #4700bb;
$black-8: rgba(0, 0, 0, 0.08);
$black-70: rgba(0, 0, 0, 0.7);
$black-80: rgba(255, 255, 255, 0.8);
$battleship-grey: #727579;
$grey: #747474;
$gradient-green: linear-gradient(106.1deg, #00ae65 0.21%, #82d200 33.37%, #a1dd40 66.56%, #40c28c 100%);
$gradient-green-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
  linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
$gradient-orange: linear-gradient(106.1deg, #ffc000 0.21%, #e54300 33.37%, #eb7240 67.01%, #ffd040 100%);
$gradient-orange-hover: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
  linear-gradient(315.2deg, #e54300 0%, #ffc000 95.02%);

$gradient-white: linear-gradient(
  106.1deg,
  rgba(255, 255, 255, 0.1) 0.21%,
  rgba(255, 255, 255, 0.1) 33.37%,
  rgba(255, 255, 255, 0.2) 67.01%,
  rgba(255, 255, 255, 0.2) 100%
);
$gradient-grey: linear-gradient(
  106.1deg,
  rgba(224, 224, 224, 0.4) 0.21%,
  rgba(224, 224, 224, 0.4) 33.37%,
  rgba(224, 224, 224, 0.8) 67.01%,
  rgba(224, 224, 224, 0.8) 100%
);

@mixin section_default_paddings {
  padding: 70px 0;
}

@mixin section_default_paddings_mobile {
  padding: 40px 0;
}

@mixin vertical_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 20px;
  max-width: 260px;
  p {
    text-align: center;
    transition: linear 250ms;
  }
  img,
  svg {
    margin-bottom: 24px;
  }
}

@mixin horizontal_card {
  display: flex;
  padding: 68px 80px 70px 60px;
  justify-content: space-between;
  .card-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 72px;

    h2 {
      margin-bottom: 16px;
    }
  }
}

@mixin card_after_scale($scale) {
  $length: 30px;
  width: $length * $scale;
  height: $length * $scale;
  border-radius: $length * $scale;
  right: -($length * math.div($scale, 2));
  bottom: -($length * math.div($scale, 2));
}

@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@mixin input-text($input-type) {
  width: 100%;
  border-radius: 10px;
  height: 56px;
  border: 1px solid transparent;
  transition: 0.2s all;
  @if $input-type == 'textarea' {
    padding-top: 16px;
    height: 104px;
  }
  #{$input-type} {
    padding-left: 16px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;

    &:-webkit-autofill {
      border-radius: 8px;
    }

    &:focus {
      outline: none;
    }

    @if $input-type == 'textarea' {
      resize: none;
    }
  }
  .input-message {
    display: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 12px 20px;
    right: 0;
    top: 70px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 600;

    &:after {
      display: block;
      position: absolute;
      top: -10px;
      right: 10px;
      content: '';
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid white;
    }
  }
  &.error {
    &:hover {
      .input-message {
        opacity: 1;
        visibility: visible;
      }
    }

    .input-message {
      animation-name: fade-in;
      animation-iteration-count: 2;
      animation-direction: alternate;
      animation-duration: 1s;
      display: block;
    }
  }
  &.dark {
    background-color: rgba(255, 255, 255, 0.1);

    &:focus-within {
      border-color: $white;
    }

    #{$input-type} {
      color: $white;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &.error {
      background-color: rgba(255, 0, 77, 0.2);
      border-color: #ffffff;
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: url(./assets/icons/ic24_alert.svg);
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;
      }
    }
  }
  &.light {
    background-color: rgba(116, 116, 116, 0.1);

    &:focus-within {
      border-color: $purple;
    }

    #{$input-type} {
      color: #181818;

      &::placeholder {
        color: rgba(24, 24, 24, 0.5);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: #181818;
      }
    }

    &.error {
      background-color: rgba(255, 0, 77, 0.1);
      border-color: #ff004d;
      position: relative;
      display: flex;
      align-items: center;

      #{$input-type} {
        color: rgba(255, 0, 77, 0.5);
        -webkit-text-fill-color: rgba(255, 0, 77, 0.5);

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: rgba(255, 0, 77, 0.5);
        }
      }

      &:after {
        content: url(./assets/icons/ic24_alert_red.svg);
        position: absolute;
        right: 16px;
        height: 24px;
        width: 24px;
      }
    }
  }
}

* {
  body {
    position: relative;
  }

  font-family: $font-family;

  ul {
    &.simple {
      li:before {
        content: '';
        display: block;
        position: absolute;
        top: 0.8em;
        left: 0;
        width: 1em;
        height: 1px;
        margin-top: -1px;
        background: currentColor;
      }
    }

    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }
}

header {
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 30px;
  z-index: 10;

  &.fixed {
    padding-top: 0;
    position: fixed;

    .logo {
      width: 95px;

      &-image {
        &--green {
          opacity: 1;
        }

        &--white {
          opacity: 0;
        }
      }
    }
  }
}

main {
  overflow: hidden;
}

.footer {
  background: $dark;
  color: $white;
  padding: 40px 0;
  overflow: hidden;

  .footer-links {
    text-align: center;
    margin-bottom: 32px;

    .footer-item {
      a {
        font-weight: 600;
        font-size: 16px;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .footer-copyright {
    color: $white-60;
    text-align: center;

    a {
      color: $white;
      transition: opacity 0.55s ease-out;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.button {
  position: relative;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  color: #ffffff;
  outline: none;
  display: inline-block;
  transition: linear 250ms;
  background-size: 300% 300%;

  &:hover {
    background-position: 100% 100%;
  }

  &--white {
    background-image: $gradient-white;
  }

  &--green {
    background-image: $gradient-green;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;

      &:hover {
        background-position: 0 0;
      }
    }
  }

  &--orange {
    background-image: $gradient-orange;
  }

  &--grey {
    color: $grey;
    background-image: $gradient-grey;

    svg {
      fill: #181818;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/icons/button_spinner.svg') 50% 50% transparent
      no-repeat;
    background-size: auto 55px;
    z-index: -5;
    opacity: 0;
    transition: opacity linear 250ms;
  }

  &--loading{
    color: transparent;
    &::after {
      z-index: 5;
      opacity: 1;
    }
  }

  &-switch-lang {
    padding: 16px;
    display: flex;

    + ul {
      visibility: hidden;
      position: absolute;
      top: calc(100% + 10px);
      left: 50%;
      transform: translate(-50%, 0);
      background: white;
      box-shadow: 0 0.125em 0.875em 0 rgb(25 46 84 / 15%);
      padding: 10px 0;
      border-radius: 0.5em;

      li {
        display: block;

        a {
          display: flex;
          align-items: center;
          padding: 12px 16px;

          &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)),
              linear-gradient(106.64deg, #00ae65 3.13%, #82d200 100%);
            color: white;
          }

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    &.open {
      background: rgba(224, 224, 224, 0.4);

      + ul {
        visibility: visible;
      }
    }

    a {
      display: inline-flex;
      align-items: center;

      svg:first-child {
        margin-right: 10px;
      }
    }
  }

  &--more-pg {
    padding: 18px 46px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-weight: 800;
  font-size: 50px;
  line-height: 1.08;
}

h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 1.1875;
}

h3 {
  font-weight: 800;
  font-size: 24px;
  line-height: 1.25;
}

h4 {
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
}

p {
  font-weight: 600;

  &.normal {
    font-weight: 400;
  }
}

.cookie-modal {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #e1e4ea;
  display: none;
  flex-direction: column;
  padding: 20px;
  max-width: 580px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgb(25 46 84 / 10%);
  z-index: 100;

  h4 {
    margin-bottom: 8px;
  }

  .button {
    margin: 0 auto;
  }

  p {
    text-align: start;
    margin-bottom: 16px;
    -webkit-text-size-adjust: 100%;
  }

  a {
    color: $green;
  }
}

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  visibility: hidden;
  z-index: 1000;
  top: 0;
  transition: background-color 0.4s ease;

  &:target {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    background: rgba(0, 0, 0, 0.6);

    &.privacy-modal {
      .modal-window {
        transform: scale(1);
      }
    }
  }

  &.privacy-modal {
    a {
      color: $purple;
    }

    .modal {
      &-window {
        transition: transform 0.4s ease;
        transform: scale(0.8);
        max-width: 65%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        background: $white;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        max-height: 98%;
      }

      &-header {
        z-index: 10;

        .modal-close {
          position: absolute;
          cursor: pointer;
          margin-right: 20px;
          margin-top: 20px;
          top: 0;
          right: 0;
          color: #8692a7;
          transition: color 0.15s ease-in-out;

          &:hover {
            color: #677186;
          }
        }
      }

      &-container {
        overflow: auto;
        text-align: left;
        padding: 48px 40px;
        background: transparent;
        position: relative;
        max-width: 100%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: none;
        max-height: unset;

        ul {
          & > li {
            list-style: outside;
            margin-left: 18px;
          }
        }

        ol {
          & > li {
            margin-bottom: 20px;
          }
        }

        h2 {
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
  }

  .modal-container {
    text-align: center;
    max-width: 480px;
    position: fixed;
    padding: 48px 40px;
    background: $white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%) scale(0.85);
    opacity: 0;
    transition: 0.4s ease;
    max-height: 98%;
    overflow-y: auto;
    overflow-x: hidden;

    &__title {
      margin-bottom: 20px;
      font-weight: 800;
      font-size: 24px;
      line-height: 1.25;
    }

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 32px;
    }

    .button {
      width: 160px;
    }

    &--xl {
      max-width: unset;
      width: 70%;
      max-width: 800px;
    }
  }

  &.visible {
    .modal-container {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
}

.slogan {
  text-align: center;
  margin-bottom: 80px;

  span {
    color: $purple;
  }
}

.input {
  @include input-text('input');
}

.textarea {
  @include input-text('textarea');
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.grey {
  color: $grey;
}

.logo {
  position: relative;
  width: 110px;
  transition: linear 250ms;
  opacity: 1;

  &-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    opacity: 1;
    transition: linear 250ms;

    &--green {
      opacity: 0;
    }
  }

  &:hover {
    opacity: 0.7;
  }
}

.nav {
  &.transparent {
    background-color: transparent;
  }

  &.colored {
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
  }
}

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  height: $topbar-height;
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;

  &-button {
    margin-left: auto;
    margin-right: 16px;
  }

  &-links {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
  }

  &-item {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;

    & + & {
      margin-left: 20px;
    }

    a {
      text-decoration: none;
      color: white;
    }
  }
}

// SECTIONS

.welcome_section {
  background: linear-gradient(107.31deg, #4700bb 0%, #951bcc 99.29%);
  color: white;
  padding-bottom: 60px;
  height: auto;

  &:not(.cases) {
    .welcome_section-container {
      align-items: center;
    }

    .welcome_section-description {
      font-size: 20px;
      line-height: 1.5;
    }

    .welcome_section-info {
      margin-bottom: 0;
    }
  }

  &-container {
    padding-top: $topbar-height + 60px;
    display: flex;
    justify-content: space-between;
  }

  &-info {
    display: flex;
    flex-direction: column;
    flex-basis: 700px;
    margin-bottom: 30px;
    padding-top: 20px;
  }

  &-app {
    display: flex;
    align-items: center;
    color: rgba(#ffffff, 0.5);
    margin-top: 60px;

    img {
      margin-right: 8px;
    }
  }

  &-title {
    margin-bottom: 24px;
  }

  &-description {
    color: rgba(#ffffff, 0.5);
    font-weight: 400;
    margin-bottom: 48px;
  }

  &-links {
    margin-right: auto;
    display: flex;

    a {
      display: block;

      &:first-child {
        margin-right: 16px;
      }
    }
  }

  &-img {
    text-align: right;

    img.header-mock {
      display: inline-block;
      filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.2));
      width: 92%;
    }
  }
}

.brand_section {
  padding-top: 40px;
  padding-bottom: 36px;

  .brand {
    &-list {
      display: flex;
      justify-content: space-between;
    }
  }
}

.section {
  padding-top: $container-indent;
  padding-bottom: $container-indent;

  &-container {
    max-width: $container-width;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: auto;
  }
}

.card-section {
  @include section_default_paddings();
}

.features_section {
  @include section_default_paddings();

  &-container {
    display: flex;
    justify-content: space-between;
  }

  .feature {
    border: 1px solid #e0e0e0;
    max-width: 260px;
    border-radius: 16px;
    flex: 1 1;
    overflow: hidden;

    h4 {
      margin-bottom: 16px;
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    &-description {
      font-weight: 400;
      margin-bottom: 16px;
    }

    &-info {
      padding: 20px;
    }

    a {
      text-decoration: none;
      color: green;

      &::after {
        content: '>';
        margin-left: 10px;
      }
    }
  }
}

.styles_section {
  @include section_default_paddings();
}

.possibilities_section {
  @include section_default_paddings();

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .posibilities_image {
      flex: 1;
    }
  }
}

.work_section {
  @include section_default_paddings();
}

.solution_section {
  @include section_default_paddings();

  &-container {
    .solution-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &--item {
        flex: 0 0 calc(33% - (40px / 3));
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .device-img {
          margin-top: auto;
          margin-bottom: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        img {
          margin-bottom: 28px;
        }

        h4 {
          margin-bottom: 16px;
          text-align: center;
        }

        p {
          text-align: center;
          margin-bottom: 64px;
        }
      }
    }

    .solution-devices {
      display: flex;
      justify-content: space-between;

      li {
        align-self: center;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .slogan {
    margin-bottom: 72px;
    text-align: center;

    span {
      color: $purple;
    }
  }
}

.information_section {
  @include section_default_paddings();
}

.community_section {
  @include section_default_paddings();
}

.security_section {
  @include section_default_paddings();
}

.demonstration_section {
  background: linear-gradient(303.52deg, #951bcc 21.76%, #4700bb 85.87%);
  padding-top: 80px;
  padding-bottom: 90px;

  &-container {
    h1 {
      color: $white;
      margin-bottom: 66px;
      text-align: center;
    }
  }
}

.team_section {
  @include section_default_paddings();
  padding-bottom: 140px;

  &-container {
    .slogan {
      margin-bottom: 24px;
    }

    .section-description {
      margin-bottom: 70px;
    }

    p {
      text-align: center;
    }
  }

  .skills-list {
    display: flex;
    justify-content: space-between;
    margin-left: -14px;
    margin-right: -14px;

    .item {
      display: flex;
      flex-direction: column;

      &:nth-child(2) .info {
        border-left: 1px solid rgba(196, 196, 196, 0.5);
      }

      &:nth-child(3) .info {
        border-left: 1px solid rgba(196, 196, 196, 0.5);
        border-right: 1px solid rgba(196, 196, 196, 0.5);
      }

      .mark {
        text-align: center;
        background: linear-gradient(341.43deg, #4700bb 10.93%, #951bcc 97.49%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 60px;
        line-height: 72px;
        font-weight: 800;
      }
    }
  }
}

.book_section {
  @include section_default_paddings();
  padding-bottom: 0;

  &-container {
    background: $dark;
    display: flex;
    height: 740px;
  }

  &-img {
    width: 50%;
    background-image: url(./assets/img_book@2x.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .webp & {
      background-image: url(./assets/img_book@2x.webp);
    }
  }

  &-form {
    padding-left: 60px;
    padding-top: 100px;
    max-width: 560px;

    .input {
      margin-bottom: 56px;
    }

    .button {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 16px;
      color: white;
    }

    p {
      margin-bottom: 48px;
      color: $white-60;
    }

    .privacy-policy {
      a {
        color: white;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

// END SECTIONS

// CARDS

.qoute-card {
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .card-info {
    padding: 50px 60px;

    .card-description {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px;
      line-height: 30px;
    }

    .card-source {
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  picture {
    flex-shrink: 0;

    img {
      height: 100%;
    }
  }
}

.card {
  border: 1px solid #e0e0e0;
  border-radius: 16px;

  &--dark {
    background: $dark;
    color: white;
  }

  &--colorful {
    background: linear-gradient(303.52deg, #951bcc 21.76%, #4700bb 85.87%);
    color: white;
  }

  &--actionable {
    transition: linear 250ms;
    position: relative;
    overflow: hidden;
    z-index: 0;

    &::after {
      content: '';
      background: linear-gradient(341.43deg, #4700bb 10.93%, #951bcc 97.49%);
      color: white;
      position: absolute;
      width: 30px;
      height: 30px;
      right: -30px;
      bottom: -30px;
      border-radius: 30px;
      transition: linear 250ms;
      z-index: -1;
    }

    svg {
      path.path-circle,
      path.path-icon {
        transition: linear 250ms;

        &--green {
          fill: #00c180;
        }

        &--red {
          fill: #ff6838;
        }

        &--yellow {
          fill: #ffcb45;
        }

        &--blue {
          fill: #0087fe;
        }
      }
    }

    &:hover,
    &.active {
      background-position: 100% 100%;
      cursor: pointer;

      h4,
      p {
        color: white;
      }

      &::after {
        @include card_after_scale(30);
      }

      svg {
        path.path-circle,
        path.path-icon {
          fill: $white;
        }
      }
    }

    &:hover {
      box-shadow: 0px 18px 34px rgba(71, 0, 187, 0.25);
    }
  }
}

.possibilities_cards {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.possibility_card {
  @include vertical_card;
}

.work_card {
  @include horizontal_card;
  align-items: center;

  .card-info {
    flex: 1;
  }
}

.information_cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.information-card {
  @include vertical_card;

  h4 {
    text-align: center;
    margin-bottom: 12px;
  }

  &:hover,
  &.active {
    cursor: default;

    .grey {
      color: $white-60;
    }
  }
}

.community_card {
  display: flex;

  .card-info {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px;

    h2 {
      margin-bottom: 16px;
    }

    p {
      &:nth-child(2) {
        margin-bottom: 16px;
      }
    }
  }

  &-img {
    flex: 1;
    width: 100%;
    height: auto;
    background: linear-gradient(234.08deg, #d6e0eb 15.34%, #eef4fa 85.4%);
    position: relative;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;

    img {
      bottom: 0;
    }
  }
}

.security_card {
  @include horizontal_card;

  p {
    color: $white-60;
  }
}

.phone_card {
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  background: transparent;

  h1 {
    margin-bottom: 24px;
    text-align: center;
    margin-top: 8px;

    &:nth-child(3) {
      margin-bottom: 0;
    }

    a {
      transition: linear 250ms;
      opacity: 1;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  p {
    color: $white;
    text-align: center;

    a {
      color: $white;
    }
  }
}

// END CARDS

.styles {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .style-block {
    display: flex;
    flex: 1 1 300px;
    flex-direction: column;

    img {
      margin-bottom: 44px;
    }

    h3 {
      text-align: center;
      margin-bottom: 44px;
    }

    ul {
      &.wrong li {
        margin-bottom: 34px;
        display: inline-flex;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: url(./assets/icons/ic36_cross.svg);
          display: block;
          margin-right: 24px;
        }
      }

      &.correct li {
        margin-bottom: 34px;
        display: inline-flex;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: url(./assets/icons/ic36_check.svg);
          display: block;
          margin-right: 24px;
        }
      }
    }
  }
}

.demonstration_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;
  position: relative;

  .final-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, transform 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: scale(0.8);

    img {
      width: 108px;
      margin-bottom: 48px;
    }

    h2 {
      margin-bottom: 16px;
    }

    p {
      color: $white-60;
    }
  }

  &.success {
    .form-container {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8);
    }

    .final-screen {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  .form-container {
    transition: opacity 1s ease-out, transform 1s ease-in-out;
    opacity: 1;
    transform: scale(1);
    text-align: center;

    .fields {
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      &_contacts {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 20px;

        .input {
          &:first-child {
            margin-right: 40px;
          }
        }
      }
    }

    button {
      align-self: center;
      margin-bottom: 20px;
    }

    .privacy-policy {
      text-align: center;
      color: $white-60;

      a {
        color: $white;
        transition: linear 250ms;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &--modal {
    margin-bottom: 0;

    .final-screen {
      h2 {
        color: $black-100;
      }

      p {
        color: $grey;
      }
    }
  }
}

.form-modal {
  h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .form-container {
    .privacy-policy {
      color: $grey;
      margin-bottom: 0;

      a {
        color: $black-100;
      }
    }
  }

  .modal-container {
    display: block;

    .button {
      width: auto;
    }
  }

  .modal-close {
    position: absolute;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 20px;
    top: 0;
    right: 0;
    color: #8692a7;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: #677186;
    }
  }
}

@import './adaptive-style.scss';
