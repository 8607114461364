@media only screen and (max-width: 2560px) {
  .topbar,
  .section-container {
    max-width: 1160px;
  }
  .demonstration_section {
    &-container {
      &.section-container {
        max-width: 1160px - 200px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .topbar,
  .section-container {
    max-width: 1080px;
  }

  .features_section {
    &-container {
      gap: 20px;
    }
  }

  .possibilities_cards {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
  }

  .community_card {
    .card-info {
      padding: 90px 60px;
    }

    &-img {
      position: relative;

      img {
        position: absolute;
      }
    }
  }

  .button {
    &-switch-lang {
      + ul {
        transform: none;
        left: auto;
        right: 0;
      }
    }
  }

  .card {
    &--actionable {
      &:hover,
      &.active {
        &::after {
          @include card_after_scale(45);
        }
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .possibility_card {
    max-width: none;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .topbar,
  .section-container {
    max-width: 904px;
  }

  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }

  .features_section {
    &-container {
      gap: 20px;
    }
  }

  .possibilities_cards {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  .possibility_card {
    padding: 20px;
    max-width: none;
    margin-bottom: 10px;

    img,
    svg {
      margin-bottom: 16px;
    }

    p {
      text-align: center;
    }
  }

  .welcome_section {
    &:not(.cases) {
      overflow: hidden;
    }

    &-container {
      padding-top: 160px;
    }

    &-img {
      .header-mock {
        transform: translate(20%, 0%) scale(1.3);
      }
    }
  }

  .community_card {
    .card-info {
      padding: 70px 30px;
    }
  }

  .team_section {
    &-container {
      .skills-list {
        flex-wrap: wrap;

        .item {
          flex: 1 1 300px;

          &:first-child {
            margin-bottom: 24px;
          }

          &:nth-child(3) {
            .info {
              border-left: none;
            }
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .book_section {
    &-container {
      height: 600px;
    }

    &-form {
      padding-right: 80px;
    }
  }
}

@media only screen and (max-width: 932px) {
  .topbar,
  .section-container {
    max-width: 812px;
  }

  .welcome_section {
    &-img {
      .header-mock {
        transform: translate(45%, 0%) scale(1.5);
      }
    }
  }

  .community_card {
    .card-info {
      padding: 40px 30px;
    }

    &-img {
      img {
        position: absolute;
      }
    }
  }

  .work_card {
    padding: 30px;
  }

  .security_card {
    padding: 30px;
  }

  .features_section {
    &-container {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;

      .feature {
        flex: 1 1 260px;
        border-radius: 24px;
        max-width: none;
      }
    }
  }
}

@media only screen and (max-width: 853px) {
  .topbar,
  .section-container {
    max-width: 722px;
  }

  .welcome_section {
    &-links {
      margin-right: 0;
      display: flex;
      flex-wrap: wrap;
    }

    &-img {
      .header-mock {
        transform: translate(45%, -10%) scale(1.6);
      }
    }
  }

  .qoute-card {
    .card-info {
      padding: 40px;
    }

    img {
      display: none;
    }
  }

  .community_card {
    .card-info {
      padding: 30px;

      h2 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  .information_cards {
    flex-wrap: wrap;

    .information-card {
      padding: 20px;
      max-width: 100%;
      flex: 1 1 220px;

      img {
        margin-bottom: 16px;
      }

      .card-info {
        flex-direction: column;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .topbar,
  .section-container {
    max-width: 708px;
  }

  .welcome_section {
    &-img {
      .header-mock {
        transform: translate(45%, -10%) scale(1.8);
      }
    }
  }

  .book_section {
    &-container {
      flex-direction: column;
      height: auto;
    }

    &-img {
      width: 100%;
      height: 600px;
    }

    &-form {
      max-width: 100%;
      padding: 60px;
      text-align: center;
    }
  }

  .qoute-card {
    .card-info {
      padding: 20px;
    }

    img {
      display: none;
    }
  }

  .brand_section {
    .brand {
      &-list {
        gap: 30px 20px;
      }
    }
  }

  .community_card {
    &-img {
      img {
        position: absolute;
      }
    }
  }

  .modal-wrapper {
    &.privacy-modal {
      .modal-window {
        max-width: none;
        width: 100%;
        border-radius: 0;
      }

      .modal-container {
        padding: 20px 20px;
      }
    }

    .modal-container {
      max-width: 100%;
      width: 100%;
    }
  }

  .cookie-modal {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;

    p {
      font-size: 13px;
    }

    h4 {
      font-size: 16px;
    }
  }

  .possibilities_section {
    &-container {
      flex-wrap: wrap;

      .posibilities_image {
        flex: 0 0 100%;

        .mockups-container {
          width: 35%;
          height: 350px;
          margin-bottom: 20px;
        }

        img {
          margin: 0 auto;
        }
      }

      .possibilities_cards {
        flex: 0 0 100%;
      }
    }
  }

  .card {
    &--actionable {
      &:hover,
      &.active {
        &::after {
          @include card_after_scale(50);
        }
      }
    }
  }

  .demonstration_form {
    .fields {
      .fields_contacts {
        .input:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 667px) {
  .logo {
    width: 90px;
    height: auto;
  }
  .button {
    font-size: 14px;
  }
  .topbar,
  .section-container {
    max-width: 540px;
  }

  h1 {
    font-size: 28px;
    line-height: 1.08;
  }

  h2 {
    font-size: 24px;
    line-height: 1.12;
  }

  h3 {
    font-size: 20px;
    line-height: 1.11;
  }

  p {
    font-size: 18px;
  }

  .security_section {
    @include section_default_paddings_mobile();
  }

  .welcome_section {
    height: auto;

    &-links {
      a:first-child {
        margin-bottom: 0;
      }
    }

    &-info {
      padding-top: 0;
    }

    &-img {
      display: none;
    }
  }

  .solution_section {
    @include section_default_paddings_mobile();

    &-container {
      .slogan {
        margin-bottom: 40px;
      }

      .solution-list {
        flex-direction: column;

        &--item {
          flex: 0 0 100%;
          margin-bottom: 0;
          margin-bottom: 24px;

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .information_section {
    @include section_default_paddings_mobile();
  }

  .features_section {
    @include section_default_paddings_mobile();

    &-container {
      gap: 20px;

      .feature {
        flex: 1 1 220px;
        max-width: none;
      }
    }
  }

  .card-section {
    @include section_default_paddings_mobile();
  }

  .styles_section {
    @include section_default_paddings_mobile();

    .slogan {
      margin-bottom: 40px;
    }

    .styles {
      flex-wrap: wrap;
    }
  }

  .work_section {
    @include section_default_paddings_mobile();
  }

  .community_section {
    @include section_default_paddings_mobile();
  }

  .brand_section {
    @include section_default_paddings_mobile();

    .brand-list {
      justify-content: space-around;
    }

    .brand {
      &-list {
        flex-wrap: wrap;

        img {
          margin: 0 auto;
        }
      }
    }
  }

  .qoute-card {
    .card-info {
      .card-description {
        font-size: 20px;
      }
    }
  }

  .community_card {
    flex-direction: column-reverse;

    &-img {
      border-bottom-right-radius: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      img {
        position: inherit;
      }
    }
  }

  .team_section {
    @include section_default_paddings_mobile();
    padding-bottom: 80px;

    &-container {
      .skills-list {
        .item {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            .info {
              border-left: none;
            }
          }

          &:nth-child(3) {
            .info {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .book_section {
    @include section_default_paddings_mobile();
    padding-bottom: 0;
  }

  .possibilities_section {
    @include section_default_paddings_mobile();
  }

  .form-modal {
    h1 {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .topbar,
  .section-container {
    max-width: 420px;
  }

  .brand_section {
    .brand-list {
      .brand-item {
        flex: 1 1 120px;
      }
    }
  }

  .welcome_section {
    &-links {
      a {
        height: 100%;
      }

      a:first-child {
        margin-bottom: 16px;
      }
    }
  }

  .security_card {
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;

    .card-info {
      margin-right: 0;

      h2,
      p {
        text-align: center;
      }
    }

    img {
      align-self: center;
      margin-bottom: 24px;
    }
  }

  .community_card {
    .card-info {
      h2,
      p {
        text-align: center;
      }
    }
  }

  .work_card {
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;

    &-img {
      align-self: center;
      margin-bottom: 24px;
    }

    .card-info {
      margin-right: 0;

      h2,
      p {
        text-align: center;
      }
    }
  }

  .phone_card {
    .phone,
    .email {
      font-size: 24px;
    }
  }
  .modal-wrapper {
    &.privacy-modal {
      .modal-header {
        padding-top: 36px;
      }

      .modal-container {
        padding: 16px;
      }
    }
  }
  .book_section {
    &-img {
      height: 400px;
    }
  }

  .card {
    &--actionable {
      &:hover,
      &.active {
        &::after {
          @include card_after_scale(37);
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .topbar,
  .section-container {
    max-width: 385px;
    min-width: 320px;
  }

  .features_section {
    &-container {
      .feature {
        border-radius: 24px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .book_section {
    &-form {
      padding: 30px;
    }
  }

  .possibility_card {
    margin-bottom: 16px;
    flex: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .styles {
    .style-block {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .information-card {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .demonstration_form {
    .fields {
      .fields_contacts {
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 16px;

        .input:first-child {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .phone_card {
    .phone,
    .email {
      font-size: 24px;
    }
  }

  .button--orange {
    padding: 12px 20px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 350px) {
  .topbar {
    .button--orange {
      font-size: 12px;
    }
  }

  .team_section {
    &-container {
      .slogan {
        font-size: 24px;
      }
    }
  }
}
